.App {
    line-height: 1;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@font-face {
    font-family: 'DunbarText';
    src: url('./assets/fonts/DUNBARTEXTREGULAR.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DunbarTextExBold';
    src: url('./assets/fonts/DUNBARTEXTEXTRA_BOLD.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bodoni72';
    src: url('./assets/fonts/Bodoni_72.ttc') format('truetype-collection');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./assets/fonts/RAILWAY.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AmericanTypewriter';
    src: url('./assets/fonts/AMERICANTYPEWRITER.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AmericanTypewriterBold';
    src: url('./assets/fonts/AMERICANTYPEWRITERBOLD.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AmericanTypewriterLight';
    src: url('./assets/fonts/AMERICANTYPEWRITERLIGHT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Edu AU VIC WA NT Pre';
    src: url('./assets/fonts/EduAUVICWANTPre-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dancing Script';
    src: url('./assets/fonts/DancingScript-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Great Vibes';
    src: url('./assets/fonts/GreatVibes-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Alex Brush';
    src: url('./assets/fonts/AlexBrush-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.basic-regular {
    font-family: 'Basic', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

input:focus-visible {
    outline: none;
}

input {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0);
}

input:focus {
    border-width: 2px;
}

button {
    margin: 0;
    padding: 0;
    line-height: normal;
    border: none;
    outline: none;
    padding-block: 0px;
    padding-inline: 0px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner {
    width: 100px;
    height: 100px;
}

.spinner-path {
    fill: none;
    /* stroke-dasharray: 28.5 161.5; */
    /* stroke-dasharray: 38 152; */
    stroke-dasharray: 47.5 142.5;
    /* stroke-dasharray: 57 133; */
    /* stroke-dasharray: 66.5 123.5; */
}

.spinner-path.primary {
    /* animation: dash1 2s reverse linear infinite; */
    animation: dash1 2.5s reverse linear infinite;
    /* animation: dash1 3s reverse linear infinite; */
}

.spinner-path.secondary {
    /* animation: dash2 2s reverse linear infinite; */
    animation: dash2 2.5s reverse linear infinite;
    /* animation: dash2 3s reverse linear infinite; */
}

@keyframes dash1 {
    0% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: -190;
    }
}

@keyframes dash2 {
    0% {
        stroke-dashoffset: 95;
    }
    100% {
        stroke-dashoffset: -95;
    }
}

.loading-box-shine {
    position: relative;
    overflow: hidden;
}

.loading-box-shine::after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(calc(((167px - 100%) / -2)) + -95px); /* Start from the left, out of view */
    width: 95px; /* Width of the light effect */
    height: 100%;
    background: linear-gradient(
        to right,
        rgba(206, 212, 218, 0) 0%,
        rgba(255, 255, 255, 0.75) 50%,
        rgba(206, 212, 218, 0) 100%
    );
    animation: shine 1.25s infinite;
    animation-timing-function: ease-in-out;
}

@keyframes shine {
    0%,
    100% {
        left: calc(calc(((167px - 100%) / -2)) + -95px); /* Starts and ends offscreen to the left */
    }
    50% {
        left: calc(((167px - 100%) / 2) + 100%); /* Moves across the div */
    }
}

input[type='checkbox'] {
    visibility: hidden;
    position: absolute;
    margin: 0px;
}

input[type='checkbox'] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
    transition: border-color 0.3s;
    margin-top: 1px;
    cursor: pointer;
}

input[type='checkbox']:checked + label:before {
    border-color: #212529;
    background-image: url('../public/check.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

input[type='checkbox'].planCheckbox + label:before {
    width: 15px;
    height: 15px;
    opacity: 0.3;
    border: 2px solid #ced4da;
    left: unset;
    top: 5.5px;
    right: 6.5px;
    cursor: default;
}

input[type='checkbox'].planCheckbox:checked + label:before {
    background-image: url('../public/check.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    opacity: 1;
}

.fade-box {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #6c757d;
    position: absolute;
    font-family: Raleway;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 26px;
    border-radius: 15px;
    background-color: #f0f0f0;
}

.fade-box.visible {
    opacity: 1;
    pointer-events: auto;
}

.checkmark-path {
    stroke-dasharray: 22;
    stroke-dashoffset: 22;
    animation: draw-check 1s ease forwards;
    animation-delay: 0.2s;
}

@keyframes draw-check {
    to {
        stroke-dashoffset: 0;
    }
}

.button {
    font-family: Raleway;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 22px;
    border-radius: 4px;
    width: 35%;
    height: 8vh;
    max-height: 50px;
    max-width: 130px;
    cursor: default;
    margin-bottom: 4vh;
}

.button.full {
    width: 100%;
    max-height: 60px;
    max-width: 390px;
}

.button.medium {
    width: 56%;
    max-width: 200px;
}

.button.short {
    max-height: 50px;
    max-width: 100px;
}

.button.mini {
    font-size: 0.9rem;
    width: 17.5%;
    height: 4vh;
    margin-bottom: 0vh;
}

.button.submit {
    background-color: #ced4da;
    color: #ffffff;
}

.button.skip {
    background-color: #ffffff;
    color: #212529;
    border: 1px solid #212529;
    cursor: pointer;
}

.button.active {
    background-color: #212529;
    cursor: pointer;
}

.button.skip.active {
    background-color: #ffffff;
}

.button.loading {
    cursor: wait;
    background-color: #ced4da;
}

.button.loading-skip {
    cursor: default;
    color: #adb5bd;
    border-color: #adb5bd;
}

.button.mobile {
    height: 7.5vh;
    max-height: unset;
    max-width: unset;
}

.button.mobile-full {
    width: 98%;
}

.button.mobile-half {
    width: 47.5%;
}

.button.mobile-mini {
    width: 22%;
}

.button.opaque-5 {
    opacity: 0.5;
    cursor: default;
}

.button.opaque-2 {
    opacity: 0.2;
    cursor: default;
}

.button.success {
    background-color: lightgreen;
    cursor: default;
}

.progress-bar-text.active {
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.8s ease;
}

.progress-bar-text.in-active {
    top: 50%;
}

.progress-bar-text.in-active.top {
    transform: translate(-50%, -250%);
    transition: all 0.8s ease;
}

.progress-bar-text.in-active.bottom {
    transform: translate(-50%, 250%);
    transition: none;
}
