html {
    font-size: 16px;
}

@media (max-height: 750px) {
    html {
        font-size: 14px; /* 1rem = 14px */
    }
}

@media (min-width: 2000px) {
    html {
        font-size: 18px;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: overlay;  
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media screen and (min-width: 851px) {
    .font-12 {
        font-size: 12px;
    }
    .font-13 {
        font-size: 13px;
    }
    .font-14 {
        font-size: 14px;
    }
    .font-16 {
        font-size: 16px;
    }
    .font-18 {
        font-size: 18px;
    }
    .font-20 {
        font-size: 20px;
    }
    .font-24 {
        font-size: 24px;
    }
    .font-32 {
        font-size: 32px;
    }
    .font-34 {
        font-size: 34px;
    }
    .font-40 {
        font-size: 40px;
    }
    .font-50 {
        font-size: 50px;
    }
    .button-width-full {
        width: 100%;
        border-radius: 4px;
    }
    .button-width-half {
        width: 200px;
        border-radius: 3px;
    }
    .button-width-short {
        width: 200px;
        border-radius: 3px;
    }
    .MuiTelInput-width-large {
        width: 100%;
    }
    .MuiTelInput-width-small {
        width: 350px;
    }
    .MuiOutlinedInput-root {
        & fieldset {
            border-color: #000000;
            border-radius: 1px;
        }
    }
    .util-creds-electric-container {
        height: 46%;
    }
    .util-creds-gas-container {
        height: 46%;
        margin-bottom: 0%;
    }
    .util-creds-seperator {
        margin-top: 3.5%;
        margin-bottom: 3.5%;
    }
    .utility_creds_received_message {
        font-size: 42px;
        text-align: center;
    }
}

@media screen and (max-width: 850px) {
    .font-12 {
        font-size: 12px;
    }
    .font-13 {
        font-size: 13px;
    }
    .font-14 {
        font-size: 14px;
    }
    .font-16 {
        font-size: 16px;
    }
    .font-18 {
        font-size: 18px;
    }
    .font-20 {
        font-size: 20px;
    }
    .font-24 {
        font-size: 24px;
    }
    .font-32 {
        font-size: 32px;
    }
    .font-34 {
        font-size: 34px;
    }
    .font-40 {
        font-size: 40px;
    }
    .font-50 {
        font-size: 50px;
    }
    .button-width-full {
        width: 100vw;
        border-radius: 0px;
    }
    .button-width-half {
        width: 100vw;
        border-radius: 0px;
    }
    .button-width-short {
        width: 49.5%;
        border-radius: 1px;
    }
    .MuiTelInput-width-large {
        width: 100%;
    }
    .MuiTelInput-width-small {
        width: 95%;
    }
    .MuiOutlinedInput-root {
        & fieldset {
            border-color: #000000;
            border-radius: 3px;
        }
    }
    .util-creds-electric-container {
        height: 46.25%;
    }
    .util-creds-gas-container {
        height: 46.25%;
        margin-bottom: 4%;
    }
    .util-creds-seperator {
        margin-top: 2.5%;
        margin-bottom: 0%;
    }
    .utility_creds_received_message {
        font-size: 38px;
        text-align: none;
    }
}

.basic_input::placeholder {
    color: #adb5bd;
}

.basic_input {
    border-color: #ced4da;
}

.basic_input:focus {
    border-color: #6c757d;
}

.basic_select.placeholder {
    color: red;
}

.basic_select:not(.placeholder) {
    color: green;
}
